#prompt-container {
    position: fixed;
    z-index: 1;
    background-color: white;
    border: solid #251866 2px;
    border-radius: 1%;
    color: #251866;
    font-family: 'Exo2';
    width: 30%;
    height: auto;
    left: 35%;
    top: 8%;
}

#prompt-text {
    text-align: center;
}
