#home-container {
    height: 100%;
    width: auto;
    padding: 0 10px 10px 10px;
    text-align: center;
}

#home-header {
    margin-top: 1%;
    color: #251866;
    font-weight: bold;
}

#home-login-header,
#home-tile-header {
    font-weight: bold;
}

#home-line {
    margin-top: 3%;
    color: black;
    border: solid black 2px;
    width: 100%;
}

#home-tile-container {
    text-align: center;
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-gap: 3rem;
    margin-top: 1%;
}

@media (min-width: 700px) {
    #home-tile-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 950px) {
    #home-tile-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1000px) {
    #home-tile-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1200px) {
    #home-tile-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

#home-tile-header {
    margin-top: 2%;
    margin-bottom: 2%;
}

#no-tiles {
    color: #251866;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

#graph-header {
    font-weight: bold;
}

#home-graph {
    width: 80%;
    margin-top: 2%;
    margin-left: 10%;
    margin-bottom: 2%;
    padding: 5px;
    background-color: white;
    border: solid black 1px;
    border-radius: 1%;
}

#graph-body {
    width: 100%;
}