#tableview {
    table-layout: fixed;
    height: auto;
    width: 100%;
    text-align: center;
    background-color: white;
    display: grid;
    justify-content: center;
}

#table-body {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-items: center;
}

#created-by-table,
#date-table,
#recieved-table{
    white-space: nowrap;
}

#table-top {
    background-color: #251866;
    color: white;
    border: solid #251866 2px;
    font-weight: bold;
    font-size: 20px;
}

.tr-table:nth-child(even) {
    background-color: #dddddd;
}

.td-table {
    padding: 10px;
    border: solid black 2px;
    cursor: pointer;
}
