#footer-container {
    position: fixed;
    justify-content: space-between;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: #251866;
    color: white;
}

.the-date {
    margin-top: 5px;
    margin-left: 1%;
    height: 1rem;
    padding: 3px;
}

#btns-footer {
    margin-right: 1%;
}

#send-btn,
#save-btn,
#footer-delete-btn,
#template-btn,
#handleHistoric-btn {
    font-weight: bold;
    color: black;
    border: solid #251866 3px;
    border-radius: 10%;
    margin: 0 10px 0 10px;
}

#send-btn {
    background-color: #9ad061;
}

#link-historic {
    text-decoration: none;
    border: none;
}

#link-save {
    text-decoration: none;
    border: none;
}

#save-btn,
#handleHistoric-btn {
    background-color: #ffc849;
}

#footer-delete-btn {
    background-color: #ef0119;
}

#template-btn {
    background-color: #24b6eb;
}

#send-btn:hover,
#save-btn:hover,
#footer-delete-btn:hover,
#template-btn:hover,
#handleHistoric-btn:hover {
    font-weight: bold;
    color: white;
    border: solid white 3px;
}
