#layout-container {
    height: auto;
    width: auto;
    padding: 0 0 10px 10px;
    
}

#remove-style {
    border: solid #e2e2e2 3px;

}

#layout-header {
    color: #251866;
    font-family: 'Exo2';
    text-align: center;
}

/* Main container for tiles */
#layout-tiles {
    margin: 0 10% 10% auto;
    margin: auto auto auto auto;
    max-width: 1400px;
    display: grid;
    justify-items: center;
    grid-gap: 1vh;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, auto);
}

#known-bugs {
    color: red;
}

@media (min-width: 100px) {
    #layout-tiles {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 700px) {
    #layout-tiles {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 950px) {
    #layout-tiles {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 1100px) {
    #layout-tiles {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 1300px) {
    #layout-tiles {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1430px) {
    #layout-tiles {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Forms */
#layout-forms {
    margin-bottom: 1%;
    width: max-content;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#layout-form-container {
    width: 50vh;
    height: auto;
    display: grid;
    justify-items: center;
    grid-gap: 1vh;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(2, auto);
}

#layout-header-form {
    width: 40vh;
}

#layout-text-form {
    margin-top: 1%;
    width: 40vh;
}

#layout-url-form {
    margin-top: 1%;
    width: 40vh;
}

#layout-content-form {
    margin-top: 1%;
    width: 40vh;
}

#layout-line {
    margin-top: 8%;
    color: black;
    border: solid black 2px;
    width: auto;
}

#selection-criteria-component {
    margin-bottom: 2%;
}

/* Bottom part */
#quiz-question-component {
    margin-bottom: 2%;
    display: grid;
    justify-items: center;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(1, auto);
}

#calendar-component {
    margin-bottom: 1%;
}

#change-component,
#important-change-component {
    margin-bottom: 3%;
    width: 40vh;
    display: flex;
    justify-content: space-between;
}

#change-component {
    margin-left: 10%;
}

#important-change-component {
    margin-top: 5%;
}

#quiz-change,
#important-change,
#question-change,
#normal-change {
    display: flex;
    float: left;
    accent-color: #251866;
}

#quiz-change,
#normal-change {
    grid-column: 1 / 2;
}

#question-change,
#important-change {
    grid-column: 3 / 4;
}



#radio-change {
    height: 30px;
    width: 30px;
}

#check-push-component {
    margin-top: 5%;
    width: 40vh;
}

.feedpush-form {
    width: 40vh;
}

.flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1%;
}

#check-this-label {
    padding: 8px;
    font-weight: bold;
    color: black;
}

#footer-component {
    width: 100%;
    height: 100%;
    margin-top: 2%;
}

#delete-container {
    position: fixed;
    z-index: 1;
    background-color: white;
    border: solid #251866 2px;
    border-radius: 1%;
    padding: 10px;
    color: #251866;
    font-family: 'Exo2';
    width: 50%;
    height: auto;
    left: 25%;
    top: 8%;
    display: grid;
    grid-gap: 0;
    justify-content: center;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(3, auto);
}

#delete-header {
    text-align: center;
    grid-row: 1 / 2;
    grid-column: 1 / 4;
}

#delete-text {
    margin-top: 5%;
    font-size: 20px;
    text-align: center;
    grid-row: 2 / 3;
    grid-column: 1 / 4;
}

#delete-btn,
#delete-back-btn {
    width: 6rem;
    font-weight: bold;
    color: black;
    border: solid black 3px;
    border-radius: 10%;
}

#delete-back-btn {
    background-color: #ffc849;
    grid-row: 3 / 4;
    grid-column: 1 / 2;
}

#delete-btn {
    background-color: #ef0119;
}

#delete-link {
    text-decoration: none;
    border: none;
    grid-row: 3 / 4;
    grid-column: 3 / 4;
}

#delete-back-btn:hover,
#delete-btn:hover {
    color: white;
    border: solid white 3px;
}


