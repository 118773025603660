#stat-container {
    height: 100%;
    width: auto;
    padding: 5rem 10px 10px 10px;
}

#stat-header {
    color: #251866;
    font-family: 'Exo2';
    text-align: center;
}

.stat-boxes {
    background-color: white;
    height: 100px;
    width: 300px;
    border: solid black 3px;
    border-radius: 2%;
    text-align: center;
    padding: 10px;
}

#stat-sent {
    margin-top: 5%;
    position: absolute;
    left: 70%;
}

#stat-login {
    margin-top: 5%;
    position: absolute;
    left: 10%;
}
