#summary-container {
    position: fixed;
    overflow-y: scroll;
    z-index: 1;
    background-color: white;
    border: solid #251866 2px;
    border-radius: 1%;
    color: #251866;
    font-family: 'Exo2';
    height: 90%;
    width: 50%;
    top: 3%;
    left: 25%;
    padding: 1rem;
    display: grid;
    grid-gap: 1vh;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(6, auto);
}

#summary-header {
    padding: 2px;
    font-weight: bold;
    text-align: center;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.summary-text {
    height: 48%;
    width: auto;
    padding: 10px;
    font-size: 22px;
    font-weight: bold;
}

#summary-text {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
}

#summary-target {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    color: blue;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(4, auto);
}

#s-target, #calendar-target {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    white-space: nowrap;
}

#location-target {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
}

#start-target {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
}

#end-target {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
}

#allday-target {
    grid-row: 2 / 3;
    grid-column: 4 / 5;
    display: flex;
    gap: 2%;
}

#type-important {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(2, 1fr);
}

#summary-type {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#summary-important {
    grid-row: 1 / 2;
    grid-column: 2 / 3;

}

#summary-quiz-container {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, auto);
}

#summary-choose {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#summary-thumbs {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
}

#summary-feedback {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
}

#summmary-quiz {
    padding: 10px;
    border-radius: 1%;
}

#quiz-answers {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
}

#recieved-summarybtn {
    grid-row: 6 / 7;
    grid-column: 1 / 2;
    background-color: lightgray;
    border-radius: 1%;
    padding: 20px;
    height: 80%;
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(2, auto);
}

#summary-received {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#summary-btn {
    margin-top: -2%;
    width: 6rem;
    height: 3rem;
    font-size: 20px;
    font-weight: bold;
    color: black;
    background-color: #9ad061;
    border: solid black 3px;
}

#summary-btn:hover {
    font-weight: bold;
    color: white;
    border: solid white 3px;
}

#link-back {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    height: 100%;
    width: auto;
    border: none;
    outline: none;
    text-decoration: none;
    border-radius: 1%;
}
