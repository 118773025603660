#feed-container {
    height: 100%;
    width: auto;
    padding: 0 10px 10px 10px;
}

#feed-header {
    color: #251866;
    font-family: 'Exo2';
    text-align: center;
    margin-bottom: 2%;
}

/* Filter */
#filter-container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
}

#render-filter {
    display: grid;
    grid-gap: 1%;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(1, 1fr);
}

#filter-checks {
    justify-content: center;
    display: flex;
    float: left;
    gap: 3%;
}

#showCreated-check, 
#showActive-check, 
#showHistoric-check {
    display: flex;
    float: left;
}

#showCreated-label, 
#showActive-label, 
#showHistoric-label {
    white-space: nowrap;
    margin-left: 5%;
    margin-top: 8%;
}

#full-count {
    margin-top: 0.4%;
    font-weight: bold;
}

#main-btns {
    display: flex;
    justify-content: center;
    float: left;
    gap: 1%;
    margin-bottom: 1%;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#main-content-btn,
#main-created-btn,
#main-important-btn,
#main-action-btn,
#clear-filter-btn {
    display: flex;
    justify-content: center;
    padding: 3px;
    white-space: nowrap;
    width: 7rem;
    height: 2rem;
    color: black;
    background-color: #ffc849;
    border: solid black 2px;
}

#tableview-btn {
    padding: 3px;
    white-space: nowrap;
    width: 7rem;
    height: 2rem;
    color: black;
    background-color: #24b6eb;
    border: solid black 2px;
}

#tableview-btn {
    margin-left: 5%;
}

#main-content-btn:hover,
#main-created-btn:hover,
#main-important-btn:hover,
#main-action-btn:hover,
#clear-filter-btn:hover,
#tableview-btn:hover,
#content-btns:hover,
#created-btns:hover,
#important-btns:hover,
#action-btns:hover,
#label-x:hover {
    color: white;
    border: solid white 2px;
}

#sub-btns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1%;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
}

#content-btns,
#created-btns,
#important-btns,
#action-btns {
    margin-bottom: 1%;
    padding: 0px;
    white-space: nowrap;
    width: 12rem;
    height: 2rem;
    font-size: 13px;
    font-weight: bold;
    color: black;
    background-color: #ffc849;
    border: solid black 2px;
}

#remove-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
}

#remove-active-filter {
    display: flex;
    float: left;
}

#btn-x {
    padding: 0px;
    width: 12rem;
    height: 2rem;
    font-size: 13px;
    font-weight: bold;
    color: black;
    background-color: #ffc849;
    border: solid black 2px;
    border-radius: 3%;
}

#label-x {
    margin-left: -2%;
    padding: 0px 0px 0px 6px;
    width: 2rem;
    height: 2rem;
    font-size: 20px;
    font-weight: bold;
    color: black;
    background-color: #ffc849;
    border: solid black 2px;
    border-radius: 3%;
}

#feed-candidate-line {
    color: black;
    border: solid black 2px;
    width: 100%;
}

/* Tiles */
#feed-tile-container {
    text-align: center;
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-gap: 3rem;
    margin-top: 1%;
}

@media (min-width: 700px) {
    #feed-tile-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 950px) {
    #feed-tile-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1000px) {
    #feed-tile-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1200px) {
    #feed-tile-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

#feed-tile {
    width: auto;
    height: auto;
    perspective: 1000px;
}

#feed-tile-front,
#feed-tile-back {
    background-color: #ffff;
    height: 22rem;
    border-radius: 1%;
    display: grid;
    grid-gap: 1%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-area: overlap;
}

#feed-flip-tile {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'overlap';
}

#tile-layout {
    grid-row: 1 / 2;
    grid-column: 1 / 4;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
}

#feed-tile-line {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
    margin-top: 5%;
    color: black;
    border: solid black 2px;
    width: 100%;
}

#flip-tile-left {
    color: #251866;
    font-size: 10px;
    height: 1rem;
    width: auto;
    margin-left: 3%;
    display: grid;
    grid-gap: 0%;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(1, auto);
    justify-items: start;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#flip-tile-right {
    margin-right: 10%;
    height: 1rem;
    width: auto;
    display: grid;
    grid-gap: 0.3vh;
    justify-content: end;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(2, auto);
    grid-row: 1 / 2;
    grid-column: 3 / 4;
}

#created-date {
    white-space: nowrap;
    margin-top: 3%;
}

#released-date,
#created-by-text {
    margin-top: -5%;
    white-space: nowrap;
}

#flip-btn,
#preview-btn {
    padding: 0px;
    height: 25px;
    width: 5rem;
    font-weight: bold;
    color: black;
    background-color: #ffc849;
    border: solid black 1px;
}

#flip-btn {
    margin-top: 5%;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#preview-btn {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
}

#flip-btn:hover,
#preview-btn:hover {
    color: white;
    border: solid white 1px;
}

#feed-tile-header-front,
#feed-tile-header-back {
    text-align: center;
    position: relative;
    color: #251866;
}

#feed-tile-header-front:hover,
#feed-tile-header-back:hover {
    border: solid #251866 2px;
}

#feed-tile-header-back {
    margin-top: -3%;
    grid-row: 3 / 4;
    grid-column: 1 / 4;
}

#front-item {
    padding: 10px;
    overflow-y: auto;
    grid-row: 3 / 7;
    grid-column: 1 / 4;
}

#front-item-link {
    display: block;
    height: auto;
    width: auto;
    border: none;
    outline: none;
    text-decoration: none;
    justify-content: center;
    grid-row: 2 / 3;
    grid-column: 1 / 4;
}

#feed-tile-text-front,
#feed-tile-text-back {
    text-align: left;
    padding: 20px;
    color: #251866;
    grid-row: 3 / 7;
    grid-column: 1 / 4;
}

#feed-tile-text-front {
    margin-top: -8%;
}

/* Category 1 */
#feed-target {
    padding: 5px;
    border: none;
}

/* Category 2 */
#tile-quiz-list {
    text-align: left;
    list-style-type: none;
}

#feed-choose,
#thumb-feedback {
    font-size: 15px;
    color: black;
    /*white-space: nowrap;*/
}

#thumb-feedback {
    display: flex;
    float: left;
    margin-left: 20%;
}

/* Categroy 3 */
#date-list {
    list-style: none;
    text-align: left;
}

/* .flip {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
}

#feed-tile-back {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
} */
