#newfeed-container {
    height: 100%;
    width: auto;
    padding: 0 10px 10px 10px;
}

#newfeed-header {
    color: #251866;
    font-family: 'Exo2';
    text-align: center;
}

#card-container {
    text-align: center;
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-gap: 5rem;
    margin-top: 5%;
}

#card {
    background-color: #ffc849;
    height: 8rem;
    width: 100%;
    border-radius: 1%;
    border: solid black 2px;
}

#card-link {
    display: block;
    height: 100%;
    width: auto;
}

#card-name {
    padding: 11%;
}

@media (min-width: 700px) {
    #card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 850px) {
    #card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 950px) {
    #card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1000px) {
    #card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1200px) {
    #card-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

#card-link {
    text-align: center;
    color: black;
    text-decoration: none;
    outline: none;
    height: 100%;
    width: 100%;
}

#card-link:hover {
    background-color: #ffe3a4;
    color: black;
    border: solid black 3px;
}
