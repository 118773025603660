#date-container {
    width: 50vh;
    height: auto;
    display: grid;
    grid-gap: 1vh;
    justify-content: center;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(1, auto);
}

#datepicker-header,
#location-header {
    margin-bottom: 1%;
}

.date-picker {
    background-color: white;
    height: 4vh;
    width: 40vh;
}

#all-day {
    display: flex;
    float: left;
}

#all-day-check {
    height: 40px;
    width: 40px;
}

#all-day-label {
    font-size: large;
    margin-top: 2.5%;
    margin-left: 2%;
}

#location-input {
    height: 4vh;
    width: auto;
}
