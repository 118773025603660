#creteria-container {
    width: 50vh;
    height: auto;
    display: grid;
    grid-gap: 2vh;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(2, auto);
}

#send-all-check {
    display: flex;
    float: left;
    justify-content: center;
    margin-bottom: 1%;
}

#send-all-container {
    text-align: center;
}

#send-all-header {
    color: #251866;
}

#send-all-label {
    margin-top: 1.5%;
    margin-left: 2%;
}

.select-creteria-form {
    display: grid;
    grid-gap: 2vh;
    justify-content: center;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(3, auto);
}

#fav-btns {
    margin-top: 2%;
}

#show-change-btn,
#add-favourites-btn {
    width: 19.6vh;
    font-weight: bold;
    color: black;
    background-color: #ffc849;
    border: solid black 1px;
    white-space: nowrap;
}

#add-favourites-btn {
    margin-left: 2%;
}

.creteria-form {
    background-color: white;
    height: 140%;
    width: 40vh;
}

#criteria-option {
    /*overflow-wrap: break-word;
    overflow: hidden;*/
}

.operator-form {
    background-color: white;
    height: 140%;
    width: 40vh;
}

.operand-form {
    background-color: white;
    height: 140%;
    width: 40vh;
}

#criteria-btn, #email-btn {
    width: 40vh;
    font-weight: bold;
    color: black;
    background-color: #ffc849;
    border: solid black 1px;
}

#criteria-btn:hover,
#show-change-btn:hover,
#add-favourites-btn:hover,
#email-btn:hover {
    color: white;
    border: solid white 1px;
}

#criteria-line {
    color: black;
    border: solid black 2px;
    width: auto;
}

#criteria-ul {
    margin-bottom: 10%;
    padding: 0 0 0 70px;
    display: grid;
    grid-gap: 2vh;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto, auto);
    list-style-type: none;
}

#criteria-li {
    padding: 5px;
    color: black;
    display: grid;
    grid-gap: 1%;
    justify-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, auto);
    border: solid black 2px;
    border-radius: 3%;
}

#delete-criteria {
    margin-top: 3%;
    height: 2.5rem;
    width: 100%;
    color: black;
    background-color: #ffc849;
    border: solid black 3px;
}

#delete-criteria:hover {
    color: white;
    border: solid white 3px;
}
