* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #e2e2e2;
    font-family: 'UlmGrotesk';
    font-weight: bold;
    overflow-x: hidden;
    /*overflow-y: hidden;*/
    /*scrollbar-color: black;*/
}

@font-face {
    font-family: 'UlmGrotesk';
    src: local('UlmGrotesk'), url('../fonts/UlmGrotesk.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Exo2';
    src: local('Exo2'), url('../fonts/Exo2.ttf') format('truetype');
    font-weight: normal;
}

.form-check-input:checked {
    background-color: #9ad061;
}

#important {
    height: 30px;
    width: 30px;
}

.blurr-container {
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.visibility-hidden {
    visibility: hidden;
}
