#quiz-component-container {
    width: 50vh;
    height: auto;
    display: grid;
    grid-gap: 1vh;
    justify-content: center;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(1, auto);
}

#quiz-top {
    background-color: #ffff;
    border: solid 2px darkgrey;
    border-radius: 2%;
}

#quiz-tableview {
    table-layout: fixed;
    text-align: center;
    height: auto;
    width: 40vh;
    display: grid;
    justify-content: center;
}

.quiz-td {
    padding: 5px;
}

#check-x {
    margin: -8% 0% 0% 5%;
    display: flex;
    float: left;
}


#delete-answer-btn {
    margin: 7% 0% 0% 5%;
    padding: 0;
    white-space: nowrap;
    height: 30px;
    width: 30px;
    background-color: #ef0119;
    color: black;
    border: solid black 2px;
}

#delete-answer-btn:hover {
    color: white;
    border: solid white 2px;
}

#quiz-middle {
    display: grid;
    grid-gap: 1vh;
    justify-items: center;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
}

#quiz-answer-input {
    width: 40vh;
    font-weight: bold;
    text-align: center;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#quiz-answer-btn {
    width: 40vh;
    font-weight: bold;
    color: black;
    background-color: #ffc849;
    border: solid black 1px;
}

#quiz-answer-btn:hover {
    color: white;
    border: solid white 1px;
}

#quiz-line {
    color: black;
    border: solid black 2px;
    width: auto;
}

#quiz-bottom {
    display: grid;
    grid-gap: 0;
    justify-content: center;
    justify-items: start;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(3, 1fr);
}

#quiz-thumbs {
    margin-right: 7%;
    display: flex;
    float: left;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#quiz-feedback {
    display: flex;
    float: left;
    grid-row: 1 / 2;
    grid-column: 3 / 4;
}

#thumbs-label,
#feedback-label {
    padding: 8px;
}

#quiz-radio-container {
    width: 40vh;
    display: grid;
    grid-gap: 0;
    justify-items: start;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(3, 1fr);
}

#change-text,
#change-alt {
    display: flex;
    float: left;
}

#alternative-change,
#text-change {
    accent-color: #251866;
    height: 25px;
    width: 25px;
}

#text-label,
#alt-label {
    padding: 3px;
}

#change-text {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
}

#change-alt {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
}
