#logout-container {
    position: fixed;
    z-index: 1;
    background-color: white;
    border: solid #251866 2px;
    border-radius: 1%;
    font-family: 'Exo2';
    width: 50%;
    height: auto;
    left: 25%;
    top: 12%;
    padding: 20px;
    display: grid;
    grid-gap: 0.5vh;
    justify-items: center;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

#logout-text {
    padding: 40px;
    text-align: center;
    color: #251866;
    font-size: 30px;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
}

#logout-btn,
#back-btn {
    width: 7rem;
    height: 3rem;
    font-size: 20px;
    font-weight: bold;
    border: solid black 3px;
}

#logout-btn {
    background-color: #ef0119;
    color: white;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
}

#back-btn {
    background-color: #ffc849;
    color: black;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
}

#logout-btn:hover {
    background-color: red;
}

#back-btn:hover {
    background-color: #ffe3a4;
}
