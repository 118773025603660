#footer-tiles-container {
    margin-top: 5%;
    height: 16rem;
    width: 100%;
    height: auto;
    background-color: #251866;
    color: white;
    grid-row: 7 / 8;
    grid-column: 1 / 4;
    border-radius: 3%;
}

#tiles-footer-container {
    margin-left: 2%;
    margin-right: 2%;
    display: grid;
    grid-gap: 1%;
    grid-template-columns: repeat(4, auto);
}

#footer-tiles-action {
    position: relative;
    top: 7px;
    font-size: 10px;
    color: white;
    grid-column: 1 / 2;
}

#footer-tiles-content {
    position: relative;
    top: 7px;
    font-size: 10px;
    color: white;
    grid-column: 2 / 3;
}

#footer-tiles-status {
    position: relative;
    top: 7px;
    font-size: 10px;
    color: white;
    grid-column: 3 / 4;
}

#footer-tiles-importance {
    position: relative;
    top: 6px;
    font-size: 10px;
    color: white;
    grid-column: 4 / 5;
}
