#header-follow {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}

#main-nav-container {
    padding: 0px 0px 0px 0px;
    background-color: #251866;
}

.me-auto {
    color: #251866;
    background-color: #251866;
}

#main-nav-right {
    margin-right: 1%;
}

#logo-to-home {
    display: flex;
    float: left;
    text-decoration: none;
    border: none;
}

#nav-header {
    margin-top: 2%;
    font-weight: bold;
    font-size: 40px;
    color: white;
}

#menu-links {
    margin-left: 5%;
    background-color: #251866;
    white-space: nowrap;
    padding: 22px;
    display: grid;
    grid-gap: 3vh;
    justify-content: center;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(5, auto);
}

#nav-link-home,
#nav-link-feed,
#nav-link-new-feed,
#nav-link-history,
#nav-link-statistic {
    color: white;
    text-align: center;
    font-size: 15px;
    text-decoration: none;
    border: solid #251866 2px;
    border-radius: 3%;
}

#nav-link-home:hover,
#nav-link-feed:hover,
#nav-link-new-feed:hover,
#nav-link-history:hover,
#nav-link-statistic:hover {
    border: solid white 2px;
}

#collasible-nav-dropdown-settings {
    color: white;
    background-color: #251866;
    border-radius: 5%;
}

#dropdown-items-settings {
    position: absolute;
    margin: -10%;
    margin-top: -6%;
    height: auto;
    width: 100%;
    right: 55%;
}

#navLink {
    color: white;
    background-color: #251866;
    padding: 0px;
    text-align: center;
    font-size: 22px;
}

.dropdown-toggle::after {
    display: none;
}

a {
    border: solid #251866 2px;
}

a:hover {
    border: solid white 2px;
}
